import { css } from "@emotion/react"
import React from "react"
import { getCategoryImage } from "../../../functions"
import { flexBoxCss } from "../../../styles"
import { Category, MarginStyle } from "../../../types"

const container = css`
  ${flexBoxCss("h", "space-between", "flex-start")}
  width: 984px;

  @media (max-width: 1024px) {
    width: auto;
  }
`

const titleContainer = css`
  ${flexBoxCss("h", "center", "center")}

  div {
    ${flexBoxCss("v", "center", "flex-start")}
    margin-left: 4px;
  }
`

const nameText = css`
  font-size: 13px;
  font-weight: 400;
  color: #111111;
  font-family: "Noto Sans KR";
`

const collapsedNameText = css`
  font-size: 13px;
  font-weight: 400;
  color: #111111;
  font-family: "Noto Sans KR";

  @media (max-width: 1024px) {
    display: none;
  }
`

const descriptionText = css`
  font-size: 26px;
  font-weight: 700;
  font-family: "나눔고딕";
  color: #111111;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
`

const collapsedDescriptionText = css`
  font-size: 26px;
  font-weight: 700;
  font-family: "나눔고딕";
  color: #000000;

  @media (max-width: 1024px) {
    font-size: 15px;
    font-weight: 700;
  }
`

const categoryImage = css`
  width: 48px;
  height: 48px;
  border-radius: 50px;
  border: 1px solid rgba(0,0,0,0.2);
  margin-right: 15px;
  background-color: white;

  @media (max-width: 1024px) {
    margin-right: 7px;
  }
`

const collapsedCategoryImage = css`
  width: 48px;
  height: 48px;
  border-radius: 50px;
  border: 1px solid rgba(0,0,0,0.2);
  margin-right: 15px;
  background-color: white;

  @media (max-width: 1024px) {
    width: 36px;
    height: 36px;
    margin-right: 3px;
  }
`

interface CategoryTitleProps {
  style?: MarginStyle
  category: Category
  isCollapsed: boolean
}

const CategoryTitle = ({ category, isCollapsed }: CategoryTitleProps) => {
  return (
    <div css={container}>
      <div css={titleContainer}>
        <img 
          src={getCategoryImage(category, false)} 
          css={isCollapsed ? collapsedCategoryImage : categoryImage}
        />
        <div>
          <span css={isCollapsed ? collapsedNameText : nameText}>{category?.description}</span>
          <span css={isCollapsed ? collapsedDescriptionText : descriptionText}>{category?.name}</span>
        </div>
      </div>
    </div>
  )
}

export default CategoryTitle
