import { css } from "@emotion/react"
import React from "react"
import { getCategoryImage } from "../../../functions"
import { flexBoxCss } from "../../../styles"
import { Category, MarginStyle } from "../../../types"
import "./style.scss"

const container = css`
  width: 90px;
  ${flexBoxCss("v", "center", "center")}
  cursor:pointer;
  background-color: "blue";
  padding: 5px;

  div {
    width: 95px;
    height: 95px;
    border-radius: 200px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    ${flexBoxCss("v", "center", "center")}
    margin-bottom: 8px;
  }

  &:hover img {
    transform: scale(1.1); /*  default */
    -webkit-transform: scale(1.1); /*  크롬 */
    -moz-transform: scale(1.1); /* FireFox */
    -o-transform: scale(1.1); /* Opera */
    transition: all 0.15s ease-in-out;
  }

  &:hover span {
    color: rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 1024px) {
    width: 80px;
    div {
      width: 80px;
      height: 80px;

      img {
        width: 44px;
        height: 44px;
      }
    }
  }

  @media (max-width: 540px) {
    width: calc(100vw / 4);
  }
`

const catergoryName = css`
  display: -webkit-box;
  font-size: 14px;
  font-weight: 500;
  padding-top: 5px;
  line-height: 18px;
  height: 39px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 80px;
  overflow: hidden;
  text-align: center;
  font-family: "나눔고딕";
  color: black;

  @media (max-width: 1024px) {
    font-size: 13px;
    width: auto;
  }
`

interface CategoryItemProps {
  onClick: () => void
  style?: MarginStyle
  category: Category
  isSelected: boolean
}

const CategoryItem = ({ onClick, style, category, isSelected }: CategoryItemProps) => {
  return (
    <div css={container} onClick={onClick} style={{ ...style }}>
      <img
        className="category-image"
        src={getCategoryImage(category, isSelected)}
        style={{ border: isSelected ? "2px solid #B53843" : undefined }}
      />
      <span css={catergoryName} style={{ 
        color: isSelected ? "#B53843" : undefined,
        fontWeight: isSelected ? 700 : undefined
      }}>
        {category.name}
      </span>
    </div>
  )
}

export default CategoryItem
